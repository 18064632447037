import * as React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { AuthComponentProps } from '@elementinsurance/cognito-auth/dist/types/AuthComponentProps'

const PRODUCTION_ACCESS = 'production_access'
export const PARTNER_PORTAL_ADMIN = 'partner_portal_admin'
export const WEBHOOK_ADMIN = 'webhook_admin'
export const PARTNER_ADMIN = 'partner_admin'
export const PROD_API_KEY_ADMIN = 'prod_api_key_admin'

interface AccessRightDefinition {
  organization: 'element' | 'externals' | 'claims-handlers'
  groups?: Array<string>
}

export const AccessRights: Record<string, AccessRightDefinition[]> = {
  Admin: [
    {
      organization: 'element',
      groups: ['admin']
    }
  ],
  Element: [
    {
      organization: 'element'
    }
  ],
  PartnerAdmin: [
    {
      organization: 'element',
      groups: ['admin', 'partner_admin', 'webhook_admin', 'prod_api_key_admin']
    }
  ],
  ProductAdmin: [
    {
      organization: 'element',
      groups: ['admin', 'product_admin']
    }
  ],
  ProductVersionAdmin: [
    {
      organization: 'element',
      groups: ['admin', 'product_version_admin']
    }
  ],
  CustomerPortalAdmin: [
    {
      organization: 'element',
      groups: ['admin', 'customer_portal_admin']
    }
  ],
  InsuranceProductAdmin: [
    {
      organization: 'element',
      groups: ['admin', 'insurance_product_admin']
    }
  ],
  UserAdmin: [
    {
      organization: 'element',
      groups: ['admin', 'user_admin']
    }
  ],
  CreateProductionPolicy: [
    {
      organization: 'element',
      groups: [PRODUCTION_ACCESS]
    }
  ],
  CreateTestPolicy: [
    {
      organization: 'element'
    },
    {
      organization: 'externals'
    }
  ],
  ProductionCustomer: [
    {
      organization: 'claims-handlers'
    },
    {
      organization: 'element',
      groups: [PRODUCTION_ACCESS]
    }
  ],
  ProductionAccessReadOnly: [
    {
      organization: 'element',
      groups: ['production_access_readonly']
    }
  ]
}

export const AuthorizationContext =
  React.createContext<AuthComponentProps | null>(null)

export function AuthorizationFilter({
  rights,
  fallback = null,
  children,
  redirect = false
}) {
  const isAuthorized = useIsAuthorized(rights)

  // @ts-ignore
  if (isAuthorized || window.Cypress) {
    return <React.Fragment>{children}</React.Fragment>
  }

  if (redirect) {
    return <Redirect to="/" />
  }

  return fallback
}

export function useIsAuthorized(rights) {
  const auth = React.useContext(AuthorizationContext)

  if (!Array.isArray(rights) || !rights.length) {
    return true
  }

  if (auth && auth.organization) {
    const allowedOrg = rights.find(
      entry => entry.organization === auth.organization
    )

    if (allowedOrg && groupsIntersect(allowedOrg, auth)) {
      return true
    }
  }

  return false
}

export function useGetAuthorized() {
  const auth = React.useContext(AuthorizationContext)

  return rights => {
    if (!Array.isArray(rights) || !rights.length) {
      return true
    }

    if (auth && auth.organization) {
      const allowedOrg = rights.find(
        entry => entry.organization === auth.organization
      )

      if (allowedOrg && groupsIntersect(allowedOrg, auth)) {
        return true
      }
    }
  }
}

AuthorizationFilter.propTypes = {
  fallback: PropTypes.node,
  redirect: PropTypes.bool,
  rights: PropTypes.arrayOf(
    PropTypes.shape({
      organization: PropTypes.string,
      groups: PropTypes.arrayOf(PropTypes.string)
    })
  )
}

export function AuthorizationFilterHOC(Component, rights, redirect) {
  return function (props) {
    return (
      <AuthorizationFilter rights={rights} redirect={redirect}>
        <Component {...props} />
      </AuthorizationFilter>
    )
  }
}

AuthorizationFilterHOC.propTypes = {
  Component: PropTypes.node.isRequired,
  redirect: PropTypes.bool,
  rights: PropTypes.arrayOf(
    PropTypes.shape({
      organization: PropTypes.string,
      groups: PropTypes.arrayOf(PropTypes.string)
    })
  )
}

function groupsIntersect(left, right) {
  if (!left.groups || !left.groups.length) {
    return true
  }

  return left.groups.some(group => right.groups.indexOf(group) > -1)
}

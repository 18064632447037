import React, { useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useGetAuthToken } from './useGetAuthToken'
import { Microfrontend } from '@d-i-p/microfrontend-loader'
import useSWRImmutable from 'swr/immutable'
import { AuthorizationContext } from '../auth/AuthorizationFilter'
import { UserService } from '../services/UserService'

export const QUERY_PARAM_NAME = 'localMicrofrontends'

export const MICROFRONTEND_NAMES = [
  'customer-portal-config',
  'customer-orders-config',
  'customer-portal-faqs',
  'product-config',
  'policy-creation',
  'product-line-config',
  'product-payment-config',
  'product-validations-config',
  'risks-group-config',
  'policy-mta',
  'customer-search',
  'api-key-management',
  'document-creation'
].sort()

type BackofficeMicrofrontendProps = {
  prodUrl: string
  localUrl: string
  name: string
  bundlerTool?: 'CraWebpack4' | 'Vite'
  additionalCustomProps: { [name: string]: any }
}

function useAccount() {
  const auth = React.useContext(AuthorizationContext)
  const cognitoUser = auth?.authData
  return useSWRImmutable<{ grants: string[] }, Error>(
    [`/user-management/account`, cognitoUser],
    UserService.account
  )
}

export function BackofficeMicrofrontend({
  prodUrl,
  localUrl,
  name,
  bundlerTool,
  additionalCustomProps
}: BackofficeMicrofrontendProps) {
  const history = useHistory()
  const match = useRouteMatch()
  const getAuthToken = useGetAuthToken()
  const { data: account } = useAccount()
  const authorizationContext = React.useContext(AuthorizationContext)
  const customProps = useMemo(
    () =>
      account && {
        getAuthToken,
        baseRouteUrl: match.url, // deprecated: use baseRoutePath
        baseRoutePath: match.url,
        browserHistory: history,
        auth: {
          grants: account.grants,
          groups: authorizationContext?.groups
        },
        ...additionalCustomProps
      },
    [
      getAuthToken,
      match.url,
      history,
      additionalCustomProps,
      account,
      authorizationContext
    ]
  )

  if (!account) {
    // we must wait until `account` loads to get the list of grants
    return null
  }

  if (!MICROFRONTEND_NAMES.includes(name)) {
    throw new Error(
      `Invalid microfrontend name [${name}], must be one of [${MICROFRONTEND_NAMES.join(
        ', '
      )}] have you forgotten to update the array in BackofficeMicrofrontend?`
    )
  }

  return (
    <Microfrontend
      baseUrl={getBaseUrl(prodUrl, localUrl, name)}
      tagName={`backoffice-${name}`}
      customProps={customProps}
      bundlerTool={bundlerTool}
    />
  )
}

function getBaseUrl(prodUrl: string, localUrl: string, name: string) {
  if (process.env.NODE_ENV !== 'development') {
    return prodUrl
  }

  const params = new URLSearchParams(window.location.search)
  const microfrontendsToLoadFromLocalUrl = (
    params.get(QUERY_PARAM_NAME) ?? ''
  ).split(',')
  return microfrontendsToLoadFromLocalUrl.includes(name) ? localUrl : prodUrl
}
